import { Box, useTheme, Grid } from '@mui/material';
import MainHeader from './components/MainHeader';
import './theme/global.css';
import { useEffect, useState, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useToaster } from './hooks/toaster';
import { Staking } from './pages/Staking';
import { Redeem } from './pages/Redeem';
import { GenerateReferral } from './pages/GenerateReferral';
import { Swap } from './components/Swap';
import { Footer } from './components/Footer';
import { WalletModel } from './components/WalletModel';
import { useCustomWeb3React } from './hooks/useCustomWeb3React';
import { useEagerConnect } from './hooks/walletConnect';
import { allowance, approve, balanceOf } from './contracts/functions/erc20';
import { ZERO_ADDRESS } from './utils/constants';
import {
	calculateEarnings,
	checkUnstakeStatus,
	stake,
	stakes,
	unstake,
	withdrawEarnings,
} from './contracts/functions/stakingContract';
import { selectDurationMsg } from './hooks/swal2';

function App() {
	const { account, chainId } = useCustomWeb3React();
	const {} = useToaster();
	const _ = useEagerConnect();

	const [contractAddress, setContractAddress] = useState('');
	const [random, setRandom] = useState(Math.random());

	const [inAmount, setInAmount] = useState('');
	const [outAmount, setOutAmount] = useState('');

	const [isApproved, setIsApproved] = useState(false);

	const [refAddr, setRefAddr] = useState('');
	const [gRefAddr, setGRefAddr] = useState(account ?? '');

	const [balance, setBalance] = useState('0');
	const [userStakes, setUserStakes] = useState('0');
	const [earnings, setEarnings] = useState('0');
	const [userUnstakeStatus, setUserUnstakeStatus] = useState('0');

	const [page, setPage] = useState(0);
	const [open, setOpen] = useState(false);
	const theme = useTheme();

	const handlerRedeem = () => setPage(1);
	const handlerRefferral = () => setPage(2);
	const handlerBack = () => setPage(0);
	const handlerOpenWallets = () => setOpen(true);

	const handlerInAmount = (e) => setInAmount(e.target.value);
	const handlerOutAmount = (e) => setOutAmount(e.target.value);

	const handlerRefAddr = (e) => setRefAddr(e.target.value);
	const handlerGRefAddr = (e) => setGRefAddr(e.target.value);

	const handlerContractAddr = (addr) => setContractAddress(addr);

	const handlerRandom = () => setRandom(Math.random());

	const handlerStake = async () => {
		stake(contractAddress, account, refAddr ? refAddr : ZERO_ADDRESS, inAmount, () => {
			handlerRandom();
		});
	};

	const handlerApprove = async () => {
		if (contractAddress !== '') {
			approve(contractAddress, account, () => {
				handlerRandom();
			});
		} else {
			selectDurationMsg();
		}
	};

	const handlerUnstake = async () => {
		unstake(contractAddress, account, outAmount, () => {
			handlerRandom();
		});
	};

	const handlerIsApproved = async () => {
		if (account && contractAddress !== '') {
			setIsApproved(await allowance(account, contractAddress));
		}
	};

	const handlerWithdrawEarnings = async () => {
		withdrawEarnings(contractAddress, account, () => {
			handlerRandom();
		});
	};

	const init = async () => {
		if (account) {
			let _balance = await balanceOf(account);
			setBalance(_balance);
			if (contractAddress) {
				let _stakes = await stakes(contractAddress, account);
				let _unstakeStatus = await checkUnstakeStatus(contractAddress, account);
				let _earnings = await calculateEarnings(contractAddress, account);

				// console.log(_balance, _stakes, _unstakeStatus, _earnings);
				setUserStakes(_stakes);
				setUserUnstakeStatus(_unstakeStatus);
				setEarnings(_earnings);
			}
		}
	};

	useEffect(() => {
		init();
		handlerIsApproved();
	}, [account, chainId, contractAddress, random]);

	useEffect(() => {
		if (account) {
			setGRefAddr(account);
		}
	}, [account]);

	useEffect(() => {
		let path = window.location.pathname;
		let _ref = path.toLowerCase().slice(1);
		let isRef = /^0x[0-9a-f]{40}$/.test(_ref);
		if (isRef) {
			setRefAddr(_ref);
		} else {
			window.history.replaceState(undefined, undefined, '/');
		}
	}, []);

	return (
		<Suspense fallback={<div>Loading</div>}>
			<>
				<WalletModel open={open} setOpen={setOpen} />
				<ToastContainer style={{ fontSize: '0.9rem' }} />
				<Grid minHeight={'100vh'} container direction='column' justifyContent={'space-between'}>
					<Grid item>
						<MainHeader connectMetaMask={handlerOpenWallets} account={account} />
					</Grid>
					<Grid item>
						<Box display={'flex'} alignItems='center' flexDirection={'column'} padding={'30px 0px'}>
							<Box
								// ${theme.palette.common.orange}
								border={`1px solid rgba(255,255,255,0.2)`}
								maxWidth='340px'
								width={'100vw'}
								// padding="0px 30px"
								// margin="0px 10px"
								style={{
									borderRadius: 20,
									padding: 1,
									background: `linear-gradient(to right, ${theme.palette.primary.main} , ${theme.palette.secondary.main})`,
								}}
							>
								<Swap
									page={page > 1 ? 1 : page}
									setPage={setPage}
									items={[
										<Staking
											valueAmount={inAmount}
											onChangeAmount={handlerInAmount}
											valueRefAddr={refAddr}
											onChangeRefAddr={handlerRefAddr}
											onClickRedeem={contractAddress !== '' ? handlerRedeem : selectDurationMsg}
											onClickReferral={handlerRefferral}
											updateContractAddr={handlerContractAddr}
											// unlocked={"1000.55"}
											unlocked={userUnstakeStatus}
											balance={balance}
											userStakes={userStakes}
											stake={
												isApproved
													? contractAddress !== ''
														? handlerStake
														: selectDurationMsg
													: handlerApprove
											}
											stakeLabel={isApproved ? "text_stake" : "text_approve"}
										/>,
										page == 1 ? (
											<Redeem
												onClickBack={handlerBack}
												unlocked={userUnstakeStatus}
												earnings={earnings}
												userStakes={userStakes}
												unstake={handlerUnstake}
												withdraw={handlerWithdrawEarnings}
												valueAmount={outAmount}
												onChangeAmount={handlerOutAmount}
											/>
										) : (
											<GenerateReferral
												onClickBack={handlerBack}
												valueGRefAddr={gRefAddr}
												onChangeGRefAddr={handlerGRefAddr}
											/>
										),
									]}
								/>
							</Box>
						</Box>
					</Grid>
					<Grid item>
						<Footer />
					</Grid>
				</Grid>
			</>
		</Suspense>
	);
}

export default App;
