import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.png';
import { Row } from './styled-components';
import '../assets/outerlink.css';

import twitter from '../assets/twitter.png';
import telegram from '../assets/telegram.png';
import discord from '../assets/discord.png';

export function Footer() {
	const theme = useTheme();
	const { t } = useTranslation(); // useTranslation hook
	return (
		<>
			<Box mt={2} />
			<Box style={{ backgroundColor: '#281D02', padding: '20px 0px' }}>
				<Container maxWidth='lg'>
					<Grid container justifyContent={'center'}>
						<Grid item>
							<Vft />
						</Grid>
						<Grid item style={{ paddingLeft: 40 }}>
							<Develop />
						</Grid>
						<Grid item style={{ paddingLeft: 40 }}>
							<Community />
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Box
				style={{
					backgroundColor: '#221902',
					padding: '20px 0px',
					display: 'flex',
					alignItems: 'stretch',
					justifyContent: 'center',
					flexDirection: 'column',
				}}
			>
				<Box
					style={{
						width: '100vw',
						maxWidth: 280,
						border: '1px solid rgba(255,255,255,0.15)',
						padding: '5px 10px',
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
						borderRadius: 50,
						alignSelf: 'center',
					}}
				>
					<Row>
						<Typography style={{ color: 'white' }} variant='subtitle1'>
							{'©'}
						</Typography>
						<Typography style={{ color: 'white', paddingLeft: 5 }} variant='subtitle1'>
							VFT, Inc.
						</Typography>
						<Typography style={{ color: theme.palette.common.orange, paddingLeft: 5 }} variant='subtitle1'>
							{t('footer_terms')}.
						</Typography>
					</Row>
					<Row style={{ justifyContent: 'space-around' }}>
						<a
							href='https://google.com'
							target='_blank'
							rel='noreferrer'
							style={{ textDecoration: 'none' }}
						>
							<Typography className='outer-link' style={{ color: '#604B16' }} variant='subtitle1'>
								Terms
							</Typography>
						</a>
						<a
							href='https://google.com'
							target='_blank'
							rel='noreferrer'
							style={{ textDecoration: 'none' }}
						>
							<Typography
								className='outer-link'
								style={{ color: '#604B16', paddingLeft: 10 }}
								variant='subtitle1'
							>
								Privacy Policy
							</Typography>
						</a>
					</Row>
				</Box>
			</Box>
		</>
	);
}

export function Community() {
	return (
		<>
			<Grid container direction={'column'}>
				<Grid item>
					<Typography
						fontWeight={'500'}
						color='common.orange'
						variant='subtitle1'
						height={35}
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						Community
					</Typography>
				</Grid>
				<Grid item>
					<Row>
						<img src={twitter} alt={'twitter'} />
						<a
							href='https://twitter.com/valuefinance_'
							target='_blank'
							rel='noreferrer'
							style={{ textDecoration: 'none' }}
						>
							<Typography
								style={{ color: '#604B16', paddingLeft: 5 }}
								variant='subtitle1'
								className='outer-link'
							>
								Twitter
							</Typography>
						</a>
					</Row>
				</Grid>

				<Grid item>
					<Row>
						<img src={telegram} alt={'twitter'} />
						<a
							href='https://t.me/ValueFichat'
							target='_blank'
							rel='noreferrer'
							style={{ textDecoration: 'none' }}
						>
							<Typography
								variant='subtitle1'
								style={{ color: '#604B16', paddingLeft: 5 }}
								className='outer-link'
							>
								Telegram
							</Typography>
						</a>
					</Row>
				</Grid>
				<Grid item>
					<Row>
						<img src={discord} alt={'twitter'} />
						<a href='#' target='_blank' rel='noreferrer' style={{ textDecoration: 'none' }}>
							<Typography
								variant='subtitle1'
								style={{ color: '#604B16', paddingLeft: 5 }}
								className='outer-link'
							>
								Discord
							</Typography>
						</a>
					</Row>
				</Grid>
			</Grid>
		</>
	);
}

export function Develop() {
	return (
		<>
			<Grid container direction={'column'}>
				<Grid item>
					<Typography
						fontWeight={'500'}
						color='common.orange'
						variant='subtitle1'
						height={35}
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						Develop
					</Typography>
				</Grid>
				<Grid item>
					<a
						href='http://github.com/valuefi'
						target='_blank'
						rel='noreferrer'
						style={{ textDecoration: 'none' }}
					>
						<Typography className='outer-link' style={{ color: '#604B16' }} variant='subtitle1'>
							Github
						</Typography>
					</a>
				</Grid>
			</Grid>
		</>
	);
}

export function Vft() {
	return (
		<>
			<Grid container direction={'column'}>
				<Grid item>
					<Row>
						<img src={logo} width='35px' alt='logo' />
						<Typography fontWeight={'500'} color='common.orange' variant='subtitle1'>
							VFT
						</Typography>
					</Row>
				</Grid>

				<Grid item>
					<a
						href='https://valuefinance.io/'
						target='_blank'
						rel='noreferrer'
						style={{ textDecoration: 'none' }}
					>
						<Typography
							className='outer-link'
							variant='subtitle1'
							style={{ color: '#604B16', paddingLeft: 5 }}
						>
							Website
						</Typography>
					</a>
				</Grid>

				<Grid item>
					<a
						href='https://bscscan.com/token/0x14e8bcd053e68a22f239b9e9bead87932465d245'
						target='_blank'
						rel='noreferrer'
						style={{ textDecoration: 'none' }}
					>
						<Typography
							variant='subtitle1'
							style={{ color: '#604B16', paddingLeft: 5 }}
							className='outer-link'
						>
							Bscscan
						</Typography>
					</a>
				</Grid>

				<Grid item>
					<a
						href='https://medium.com/@ValueFi/how-to-stake-on-vft-stake-dapp-faq-94bbcffe4c88'
						target='_blank'
						rel='noreferrer'
						style={{ textDecoration: 'none' }}
					>
						<Typography
							variant='subtitle1'
							style={{ color: '#604B16', paddingLeft: 5 }}
							className='outer-link'
						>
							FAQ
						</Typography>
					</a>
				</Grid>
			</Grid>
		</>
	);
}
