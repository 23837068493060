export const CHAIN_ID = 56;
export const INFURA_KEY = "c6dbbd4cb8cc4dc9b1c9cf9bb5404910";

export const ETH_CHAIN_ID = 56;

export const CONTRACT_ADDRESSES = {
  stakingContract_week: "0x1d93e4693b5300d3a15d8f72ce3ddc3df982d470",
  stakingContract_month: "0x2d27e1a3DC04D87F9A5A0A420eC56f2363f00D9E",
  stakingContract_year: "0xEd3F0008b7144841be5B35c7c0eDcbEa1BD3d259",
  erc20Contract_VTF: "0x14E8BCd053e68A22f239b9e9bEaD87932465D245",
};

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const UINT256_MAX =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";
